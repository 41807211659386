import { css } from 'linaria';

import { colors, fonts as fontFamilies } from '../../tokens';

import robotoBoldWoff from '../../fonts/Roboto/Roboto-Bold-subset.woff';
import robotoBoldWoff2 from '../../fonts/Roboto/Roboto-Bold-subset.woff2';

import robotoRegularWoff from '../../fonts/Roboto/Roboto-Regular-subset.woff';
import robotoRegularWoff2 from '../../fonts/Roboto/Roboto-Regular-subset.woff2';

import stencilWoff from '../../fonts/Stencil/octin-stencil-rg-subset.woff';
import stencilWoff2 from '../../fonts/Stencil/octin-stencil-rg-subset.woff2';

export const reset = css`
  :global() {
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    body {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      background-color: ${colors.white};
      color: ${colors.darkGrey};
      margin: 0;
      padding: 0;
      text-rendering: optimizeLegibility;
    }

    a {
      color: ${colors.blue};
    }
  }
`;

export const fonts = css`
  :global() {
    @font-face {
      font-family: 'Octin';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(${stencilWoff2}) format('woff2'),
        url(${stencilWoff}) format('woff');
    }

    @font-face {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: local('Roboto'), url(${robotoRegularWoff2}) format('woff2'),
        url(${robotoRegularWoff}) format('woff');
    }

    @font-face {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: local('Roboto'), url(${robotoBoldWoff2}) format('woff2'),
        url(${robotoBoldWoff}) format('woff');
    }
  }
`;

export const content = css`
  padding-top: 5rem;

  @media (min-width: 1020px) {
    padding-top: 9rem;
    min-height: calc(100vh - 3.8rem);
  }
`;

export const homeContent = css`
  padding-top: 0;
`;

export const backButtonContainer = css`
  position: fixed;
  top: 9.8rem;
  left: 0;
  width: 100%;
`;

export const backButtonInner = css`
  max-width: 98.3125rem;
  padding: 1rem 1.5rem 0;
  margin: 0 auto;
`;

export const backButton = css`
  display: none;
  z-index: 1;
  cursor: pointer;
  background: none;
  border: 0;
  color: ${colors.darkGrey};
  font-family: ${fontFamilies.stencil};
  font-size: 1.875rem;
  transition: color 0.2s ease;
  text-decoration: none;

  @media (min-width: 1020px) {
    display: inline-block;
  }

  :hover {
    color: ${colors.pink};
  }
`;
