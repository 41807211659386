import { css } from 'linaria';

import { colors, fonts } from '../../tokens';

export const footer = css`
  background-color: ${colors.superDarkGrey};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
`;

export const inner = css`
  max-width: 98.3125rem;
  padding: 0 1.5rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }
`;

export const linksContainer = css`
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

export const item = css`
  display: block;
  padding: 0.6rem 1rem;
  color: ${colors.white};
  font-family: ${fonts.sans};
  font-size: 1.2rem;
  text-decoration: none;
  transition: color 0.2s ease;

  &:hover,
  &:focus {
    color: ${colors.pink};
  }

  @media screen and (min-width: 1220px) {
    font-size: 1.6rem;
    padding: 0.8rem 2rem;
  }
`;

export const smLinksContainer = css`
  display: flex;
  align-items: center;
  margin: 1.5rem 0 0;

  @media screen and (min-width: 768px) {
    margin: 0;
  }
`;

export const smIcon = css`
  color: white;
  margin: 0 1rem;

  @media screen and (min-width: 1220px) {
    margin: 0 2rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    fill: currentColor;
    transition: all 0.2s ease;
  }

  :hover {
    color: ${colors.pink};
  }
`;

export const igIcon = css`
  width: 1.32rem;
  height: 1.32rem;

  @media screen and (min-width: 1220px) {
    width: 2rem;
    height: 2rem;
  }
`;

export const fbIcon = css`
  width: 0.8rem;
  height: 1.48rem;

  @media screen and (min-width: 1220px) {
    width: 1.2rem;
    height: 2.25rem;
  }
`;

export const twIcon = css`
  width: 1.48rem;
  height: 1.23rem;

  @media screen and (min-width: 1220px) {
    width: 2.25rem;
    height: 1.87rem;
  }
`;
