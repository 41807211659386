import { cx } from 'linaria';
import Link from 'gatsby-link';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import TwitterIcon from '../../content/icons/twitter.svg';
import InstagramIcon from '../../content/icons/instagram.svg';
import FacebookIcon from '../../content/icons/facebook.svg';

import {
  footer,
  inner,
  item as itemStyle,
  linksContainer,
  smIcon,
  smLinksContainer,
  igIcon,
  fbIcon,
  twIcon,
} from './footer.style';

const Footer = () => {
  const {
    site: {
      siteMetadata: { twitterURL, instagramURL, facebookURL },
    },
  } = useStaticQuery(graphql`
    query {
      navigation: navigationYaml {
        items {
          label
          target
        }
      }

      backgroundImage: imageSharp(
        fluid: { originalName: { eq: "menu-background.png" } }
      ) {
        fluid(maxWidth: 1000, webpQuality: 2, pngQuality: 2) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }

      site {
        siteMetadata {
          twitterURL
          donateURL
          instagramURL
          facebookURL
        }
      }
    }
  `);

  return (
    <footer className={footer}>
      <nav className={cx(inner)}>
        <div className={linksContainer}>
          <Link to="/contact" className={itemStyle}>
            Contact
          </Link>
          <Link to="/privacy" className={itemStyle}>
            Privacy Policy
          </Link>
          <Link to="/imprint" className={itemStyle}>
            Legal Disclosure
          </Link>
        </div>

        <div className={smLinksContainer}>
          <a
            href={instagramURL}
            aria-label="Follow LouiseMichel on instagram"
            className={cx(smIcon, igIcon)}
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>

          <a
            href={facebookURL}
            aria-label="Follow LouiseMichel on facebook"
            className={cx(smIcon, fbIcon)}
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>

          <a
            href={twitterURL}
            aria-label="Follow LouiseMichel on twitter"
            className={cx(smIcon, twIcon)}
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon />
          </a>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
