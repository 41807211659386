import React, { useContext } from 'react';
import { cx } from 'linaria';
import { useStaticQuery, graphql } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import GatsbyImage from 'gatsby-image';

import AppContext from '../../context';

import TwitterIcon from '../../content/icons/twitter.svg';
import InstagramIcon from '../../content/icons/instagram.svg';
import FacebookIcon from '../../content/icons/facebook.svg';

import {
  nav,
  fixed,
  navInner,
  menuItems,
  menuItem,
  itemDonate as itemDonateStyle,
  itemInner as itemInnerStyle,
  logo,
  actionItems,
  socialButton,
  instagramButton,
  facebookButton,
  twitterButton,
} from './navigation.style';

import fluid from '../../mixins/fluid.style';

const Navigation = ({ isHomePage }) => {
  const {
    navigation: { items },
    backgroundImage,
    site: {
      siteMetadata: { twitterURL, instagramURL, facebookURL },
    },
  } = useStaticQuery(graphql`
    query {
      navigation: navigationYaml {
        items {
          label
          target
        }
      }

      backgroundImage: imageSharp(
        fluid: { originalName: { eq: "menu-background.png" } }
      ) {
        fluid(maxWidth: 1000, webpQuality: 2, pngQuality: 2) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }

      site {
        siteMetadata {
          twitterURL
          instagramURL
          facebookURL
        }
      }
    }
  `);

  const { showDonateModal, setShowDonateModal } = useContext(AppContext);

  return (
    <nav className={cx(nav, !isHomePage && fixed)}>
      <div className={navInner}>
        <AnchorLink to="/" className={logo}>
          Louise Michel
        </AnchorLink>

        {items && (
          <div className={menuItems}>
            {items.map(({ label, target }) => (
              <AnchorLink key={target} to={target} className={menuItem}>
                <span className={itemInnerStyle}>{label}</span>
              </AnchorLink>
            ))}
            <a href="https://shop.mvlouisemichel.org/" target="_blank" rel="noreferrer" className={menuItem}>
              <span className={itemInnerStyle}>SHOP</span>
            </a>
          </div>
        )}

        <div className={actionItems}>
          <button
            type="button"
            className={cx(menuItem, itemDonateStyle)}
            onClick={() => setShowDonateModal(!showDonateModal)}
          >
            <span className={itemInnerStyle}>Donate</span>
          </button>

          <a
            href={instagramURL}
            aria-label="Follow LouiseMichel on instagram"
            className={cx(socialButton, instagramButton)}
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>

          <a
            href={facebookURL}
            aria-label="Follow LouiseMichel on facebook"
            className={cx(socialButton, facebookButton)}
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>

          <a
            href={twitterURL}
            aria-label="Follow LouiseMichel on twitter"
            className={cx(socialButton, twitterButton)}
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon />
          </a>
        </div>
      </div>

      <GatsbyImage
        fluid={backgroundImage.fluid}
        fadeIn={false}
        style={{
          height: fluid(1, 2),
          left: '0',
          position: 'absolute',
          top: '100%',
          width: '100%',
          zIndex: 20,
        }}
        imgStyle={{
          objectFit: 'fill',
        }}
      />
    </nav>
  );
};

export default Navigation;
