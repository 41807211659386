import { css } from 'linaria';

export const modal = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

export const open = css`
  visibility: visible;
  opacity: 1;
  z-index: 100;
`;

export const overlay = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const content = css`
  width: 640px;
  padding: 20px;
  background: white;
  position: relative;
  z-index: 10;
`;

export const closeButton = css`
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  @media (min-width: 800px) {
    width: 5rem;
    height: 5rem;
    top: 1rem;
    right: 1rem;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;
