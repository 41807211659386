import React, { useState, useEffect } from 'react';
import { cx } from 'linaria';
import AppContext from '../../context';

import Footer from '../Footer';
import Metadata from '../Metadata';
import Navigation from '../Navigation';
import DonateModal from '../DonateModal';

import { fonts, reset, content, homeContent } from './layout.style';

const Layout = ({ children, pageContext: { frontmatter } }) => {
  const [showDonateModal, setShowDonateModal] = useState(false);
  const isHomePage = !!frontmatter.homePage;

  useEffect(() => {
    document.getElementById('donation-modal').style.visibility = showDonateModal
      ? 'visible'
      : 'hidden';
  }, [showDonateModal]);

  return (
    <div className={cx(fonts, reset)}>
      <AppContext.Provider value={{ showDonateModal, setShowDonateModal }}>
        <Metadata {...frontmatter} />
        <Navigation isHomePage={isHomePage} />
        <div className={cx(content, isHomePage && homeContent)}>{children}</div>
        <Footer />
        <DonateModal />
      </AppContext.Provider>
    </div>
  );
};

export default Layout;
