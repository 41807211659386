import { useTitle, useMeta, useTitleTemplate, useLang } from 'hooked-head';
import { useStaticQuery, graphql } from 'gatsby';

const createMetadataService = (prefix, data, imageURL) => {
  Object.keys(data).forEach((name) => {
    const content = data[name];

    if (name !== 'image') {
      useMeta({ name: `${prefix}:${name}`, content });
    } else {
      useMeta({ name: `${prefix}:${name}`, content: imageURL });
    }
  });
};

const Metadata = ({
  title,
  description,
  twitter = {},
  facebook = {},
  homePage = false,
}) => {
  const {
    site: {
      siteMetadata: { siteURL },
    },
    image,
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteURL
        }
      }

      image: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fixed(width: 1000, height: 500) {
            src
            originalName
          }
        }
      }
    }
  `);

  const imageURL = `${siteURL}${image.childImageSharp.fixed.src}`;

  useLang('en');

  if (!homePage) {
    useTitleTemplate('%s | M.V. Louise Michel');
  }

  useTitle(title);

  if (description) {
    useMeta({ name: 'description', content: description });
  }

  createMetadataService('twitter', twitter, imageURL);
  createMetadataService('og', facebook, imageURL);

  return null;
};

export default Metadata;
