/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useContext } from 'react';
import { cx } from 'linaria';
import AppContext from '../../context';
import { modal, open, overlay, closeButton } from './donateModal.style';

const DonateModal = () => {
  const { showDonateModal, setShowDonateModal } = useContext(AppContext);
  return (
    <div className={cx(modal, showDonateModal && open)}>
      <div
        className={overlay}
        onClick={() => setShowDonateModal(!showDonateModal)}
      />
      <button
        type="button"
        className={closeButton}
        onClick={() => setShowDonateModal(false)}
      >
        <svg viewBox="0 0 700 700">
          <path
            fill="#FFFFFF"
            d="m369.88 279.95 154.89-154.89c5.4883-5.4883 5.4883-14.391 0-19.879-5.375-5.3203-14.559-5.3203-19.879 0.054687l-154.89 154.84-154.84-154.84c-5.3203-5.375-14.559-5.375-19.824 0-5.543 5.4883-5.543 14.391 0 19.824l154.84 154.89-154.95 154.9c-5.4297 5.4336-5.4297 14.336 0.058594 19.879 5.3203 5.3203 14.504 5.3203 19.824 0l154.89-154.89 154.89 154.89c2.7422 2.6328 6.2148 4.0898 9.9688 4.0898 3.8086 0 7.3359-1.457 9.9688-4.1445 5.4883-5.4883 5.4883-14.391 0-19.824z"
          />
        </svg>
      </button>
      {/* The donation widget lives inside html.jsx. Not ideal, but the only workable solution I could find */}
    </div>
  );
};

export default DonateModal;
