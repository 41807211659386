import { css } from 'linaria';

import { colors, fonts } from '../../tokens';

export const nav = css`
  align-items: center;
  background-color: ${colors.pink};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  padding-top: 0.75rem;
  position: relative;
  width: 100%;
  z-index: 9;
`;

export const fixed = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
`;

export const navInner = css`
  max-width: 98.3125rem;
  padding: 1rem 1.5rem 0;
  width: 100%;

  @media screen and (min-width: 700px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

export const logo = css`
  font-family: ${fonts.stencil};
  font-size: 2.67rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0;
  margin-top: 0;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${colors.white};

  @media screen and (min-width: 1400px) {
    font-size: 3.5625rem;
  }
`;

export const menuItems = css`
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem 0;
`;

export const menuItem = css`
  font-family: ${fonts.stencil};
  font-size: 1.17rem;
  line-height: 1.2;
  padding: 0rem 0.5rem;
  margin: 0.5rem 0.3rem;
  color: ${colors.white}
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;

  @media screen and (min-width: 1200px) {
    font-size: 1.5625rem;
  }

  @media screen and (min-width: 1400px) {
    padding: 0rem 1rem;
    margin: 0.5rem 0.6rem;
  }

  ::after {
    background-color: ${colors.darkGrey};
    height: calc(100% + 0.4rem);
    left: -0.2rem;
    position: absolute;
    top: -0.2rem;
    transform: rotate(-1.5deg);
    width: calc(100% + 0.4rem);
    z-index: 1;

    @media screen and (min-width: 1400px) {
      height: calc(100% + 0.8rem);
      left: -0.3rem;
      top: -0.4rem;
      width: calc(100% + 0.6rem);
    }
  }

  :hover,
  :focus {
    color: ${colors.white};
  }

  :hover::after,
  :focus::after {
    content: '';
  }
`;

export const itemDonate = css`
  color: ${colors.white};
  transition: color 0.2s ease;

  ::after {
    content: '';
  }

  :hover,
  :focus {
    color: ${colors.pink};
  }
`;

export const itemInner = css`
  position: relative;
  z-index: 2;
`;

export const actionItems = css`
  align-items: center;
  display: flex;
`;

export const socialButton = css`
  margin-left: 1.25rem;
  color: ${colors.darkGrey};
  svg {
    width: 100%;
    height: 100%;

    path {
      fill: currentColor;
      transition: all 0.2s ease;
    }
  }

  :hover {
    color: ${colors.white};
  }

  @media screen and (min-width: 1400px) {
    margin-left: 2.25rem;
  }
`;

export const instagramButton = css`
  width: 28.5px;
  height: 28.5px;

  @media screen and (min-width: 1400px) {
    width: 38px;
    height: 38px;
  }
`;
export const facebookButton = css`
  width: 17.25px;
  height: 32.25px;

  @media screen and (min-width: 1400px) {
    width: 23px;
    height: 43px;
  }
`;
export const twitterButton = css`
  width: 32.25px;
  height: 26.25px;

  @media screen and (min-width: 1400px) {
    width: 43px;
    height: 35px;
  }
`;
